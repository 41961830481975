import { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Notiflix from 'notiflix';
import { useDispatch } from 'react-redux';
import {
  updateEntryThunk,
  getTodayEntriesThunk,
} from '../../redux/hydrationEntries/operations';
import {
  AmountText,
  BtnSaveWrapper,
  BtnWrapper,
  StyledDateWrapper,
  StyledDecrementIcon,
  StyledIncrementIcon,
  TimeGlobalStyles,
  ValueText,
  ModalWrapper,
} from '../AddWaterModal/AddWaterModal.styled';
import {
  EnteredText,
  FormStyledIn,
  SvgGlass,
  TimeValue,
  WaterEditContainer,
} from './EditWaterModal.styled';

// const curTimeZone = new Date().getTimezoneOffset() / 60;
const getCurTime = date => {
  const d = new Date(date);
  const t = d.getHours();
  // d.setHours(t + curTimeZone);
  d.setHours(t + 0);
  return d;
};

const EditWaterModal = ({ data, close }) => {
  const [counterValue, setCounterValue] = useState(data.amount);
  const [startDate, setStartDate] = useState(getCurTime(data.time));

  const dispatch = useDispatch();

  const handleUpdate = evt => {
    const { name } = evt.currentTarget;
    switch (name) {
      case 'decrement':
        setCounterValue(state => state - 50);
        break;
      case 'increment':
        setCounterValue(state => state + 50);
        break;
      case 'input':
        const value = Number(evt.target.value);
        const inputValue = Math.min(Math.max(value), 5000);
        setCounterValue(inputValue);
        break;
      default:
    }
  };

  const handleSave = async evt => {
    evt.preventDefault();
    if (counterValue === 0) {
      Notiflix.Notify.warning('Please enter a non-zero value for water.');
      return;
    }
    if (counterValue < 0 || counterValue === '') {
      Notiflix.Notify.warning('Please enter a valid positive value for water.');
      return;
    }

    // const newWaterParams = { amount: counterValue, time: getDbTime(startDate) };
    startDate.setHours(startDate.getHours() - 1);
    const newWaterParams = { amount: counterValue, time: startDate };

    dispatch(updateEntryThunk({ ...newWaterParams, entryId: data._id }))
      .then(() => {
        Notiflix.Notify.success('Water value updated successfully!');
        dispatch(getTodayEntriesThunk());
        close();
      })
      .catch(error => {
        Notiflix.Notify.failure(
          `Failed to update water value: ${error.message}`
        );
      });
  };

  const timePeriod = useMemo(() => {
    const minTime = new Date();
    minTime.setHours(0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 55, 0);
    return { min: minTime, max: maxTime };
  }, []);

  return (
    <ModalWrapper>
      <ValueText>Correct entered data:</ValueText>
      <WaterEditContainer>
        <SvgGlass />
        <EnteredText>{data.amount} ml</EnteredText>
        <TimeValue>
          {getCurTime(data.time).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
        </TimeValue>
      </WaterEditContainer>
      <AmountText>Amount of water:</AmountText>
      <BtnWrapper>
        <button
          type="button"
          name="decrement"
          onClick={handleUpdate}
          disabled={counterValue === 0}
        >
          <StyledDecrementIcon aria-label="decrement_button" />
        </button>
        <span>{counterValue}ml</span>
        <button type="button" name="increment" onClick={handleUpdate}>
          <StyledIncrementIcon aria-label="increment_button" />
        </button>
      </BtnWrapper>
      <FormStyledIn>
        <label>
          Recording time:
          <StyledDateWrapper>
            <DatePicker
              selected={startDate}
              onChange={date => {
                setStartDate(date);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              minTime={timePeriod.min}
              maxTime={timePeriod.max}
              timeZone="UTC"
            />
            <TimeGlobalStyles />
          </StyledDateWrapper>
        </label>
        <label>
          Enter the value of the water used:
          <input
            name="input"
            type="number"
            value={counterValue}
            onChange={evt => {
              if (
                (evt.nativeEvent.inputType === 'deleteContentBackward' ||
                  evt.nativeEvent.inputType === 'deleteContentForward') &&
                counterValue === 0
              ) {
                setCounterValue('');
              } else {
                handleUpdate(evt);
              }
            }}
            min="1"
            max="5000"
          />
        </label>
        <BtnSaveWrapper>
          <p>{counterValue}ml</p>
          <button onClick={handleSave}>Save</button>
        </BtnSaveWrapper>
      </FormStyledIn>
    </ModalWrapper>
  );
};

export default EditWaterModal;
